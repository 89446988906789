import React from 'react';
import { kebabCase } from 'lodash';
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import PageTransition from 'gatsby-plugin-page-transitions';
import { injectIntl } from 'react-intl';


import Link from '../components/LocalizedLink';
import withLayout from '../layout';
import SEO from '../components/seo';

const BlogPage = (props) => {
    const posts = props.data.allMarkdownRemark.edges;
    const { intl } = props;
    const titleText = intl.formatMessage({id: 'blog.blog-title'});

    return (
        <PageTransition>
            <SEO title={titleText} />
            <div className="post-list">
            {posts.map(post => (
                <div key={post.node.id} className="post-list__item">
                    <div className="post-list__thumbnail">
                        <Link to={post.node.fields.slug}>
                            <GatsbyImage image={post.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData} />
                        </Link>
                    </div>
                    <div className="post-list__content">
                        <h2>{post.node.frontmatter.title}</h2>
                        {post.node.frontmatter.tags ? (
                            <div className="tags-container">
                                <ul className="taglist">
                                    {post.node.frontmatter.tags.map(tag => (
                                        <li key={tag + `tag`}>
                                            <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                        <p>{post.node.frontmatter.date}</p>
                        <div className="post-list__excerpt">
                            <p>{post.node.excerpt}></p>
                        </div>
                        <Link to={post.node.fields.slug}>Read More</Link>
                    </div>
                </div>
                ))}
            </div>
        </PageTransition>
    );
};

const customProps = {
    localeKey: 'blog',
};

export default withLayout(customProps)(injectIntl(BlogPage));


// Get all markdown data, in descending order by date, and grab the id, excerpt, slug, date, and title
export const pageQuery = graphql`{
  allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}) {
    edges {
      node {
        id
        excerpt(pruneLength: 250)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          tags
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 200, height: 200, layout: FIXED)
            }
          }
        }
      }
    }
  }
}
`;
